app.controller('RenovarCtrl', function($scope) {
    $scope.costos = $costos;
    $scope.descuentos = $descuentos;

    $scope.desc = [];
    $scope.toggle = function (item, list) {
        var idx = list.indexOf(item);
        if (idx > -1) list.splice(idx, 1);
        else list.push(item);
    };
    $scope.exists = function (item, list) {
        return list.indexOf(item) > -1;
    };

    $scope.total = function(){
        $scope.total=0;
        $scope.aux =0 ;
        angular.forEach($costos, function($costo,$key) {
            $descuento = $scope.descuento($costo['concepto_id']);
            $aux = $costo['vlrtotal'] - ($costo['vlrtotal']* $costo['cantCuota'] * $descuento /100);
            $scope.total += $aux;
        });
    };


    $scope.descuento = function($concepto){
        $desc = 0;
        angular.forEach($scope.descuentos, function($descuento,$key) {
            if($descuento['concepto_id'] ==  $concepto){
                $desc += $descuento['porcentaje'];
            }
        });
        return $desc;
    };
    $scope.cuota ={
        'cantidad' : 10,
        'fecha' : new Date(),
    };


    $scope.cuotasDef  = function(){
        var $cuotasDef = [];

        angular.forEach($costos, function($costo,$key) {
            if($costo['cantCuota'] == 1){
                $descuento = $scope.descuento($costo['concepto_id']);
                $cuotasDef.push({
                    'ncuota':0 ,
                    'concepto' : $costo['concepto'],
                    'valorCobro':$costo['vlrtotal'],
                    'fechaCobro' : $scope.cuota.fecha,
                });

            }else{

                for(i=0;i<$costo['cantCuota'];i++) {
                    $descuento = $scope.descuento($costo['concepto_id']);
                    $meses = i +1;
                    $cuotasDef.push({
                        'ncuota': i +1 ,
                        'concepto' : $costo['concepto'],
                        //'valorCobro':$costo['valor'] - ($costo['valor'] * $descuento / 100),
                        'valorCobro':$costo['vlrtotal']/$costo['cantCuota'],
                        'fechaCobro' : $scope.cuota.fecha.setMonth($scope.cuota.fecha.getMonth()+ 1)
                    });
                }
                $scope.cuota.fecha.setMonth($scope.cuota.fecha.getMonth()- $meses );

            }

            //this.push(key + ': ' + value);
        });
        $scope.cuotas =$cuotasDef;
    };

    $scope.cuotasDef() ;

    $scope.$watch($scope.total);
});

app.controller('PagoCtrl', function($scope) {
    $scope.valorotros 	= 0;
    $scope.valordcto 	= 0;
    $scope.cuotas 		= $cuotas;
    $scope.cuota 		= [];

    $scope.valorcuota = 0;

    $scope.calcular = function(){
        if(!$scope.abono){
            $scope.valorotros=0;
            $scope.valorcuota = 0
            $scope.cuota.forEach(function($cuota, $key) {
                $scope.valorcuota += parseInt($cuota.valorCobro);
            });
            $scope.totalCuota = angular.toJson($scope.cuota);
        }else{
            $scope.valorcuota = 0;
        }
    };

    $scope.total = function(){
        if(!$scope.abono){
            $scope.valortotal = parseInt($scope.valorcuota) - $scope.valordcto;
        }else{
            $scope.valortotal = parseInt($scope.valorotros) * $scope.cuota.length - $scope.valordcto;
        }
    };

    $scope.fecha = new Date();
    $scope.$watch($scope.calcular);
    $scope.$watch($scope.total);
});

app.controller('progController', function($scope) {
    $scope.checkTodosGrados = true;
    $scope.cantidad = 10;
    $scope.fecha = new Date();

    $scope.calcularFechas  = function(){
        $fecha = new Date($scope.fecha);
        var i=1;
        var $cuotas = [];
        for(i=1;i<=$scope.cantidad;i++) {
            $cuotas.push({
                'ncuota':i,
                'fechaCobro' :  $fecha.setMonth($fecha.getMonth()+1)
            });
        }
        $scope.cuotas = $cuotas;
    };
    $scope.calcularFechas();

});

app.controller('FacturarController', function($scope,$timeout, $q, $log, $http) {

    $scope.spinner = false;
    $scope.tipoFactura =1;

    $scope.prefijoG = 'SJS'; //1 a 1000
    $scope.prefijo = 'SJE'; //1 a 10000

    $scope.conceptos=[
        {
            'id' : '',
            'valor' : '',
            'cantidad' : 1,
            'porcentaje' : ''
        }
    ];
    $scope.addConcepto = function() {
        $scope.conceptos.push(
            {
                'id' : '',
                'valor' : '',
                'cantidad' : 1,
                'porcentaje' : ''
            }
        );
    };

    $scope.deleteConcepto = function(concepto) {
        $scope.conceptos.splice($scope.conceptos.indexOf(concepto),1);
    };

    $scope.total = function() {
        $scope.valorTotal = 0;
        $scope.iva = 0;
        $scope.subtotal = 0;
        angular.forEach($scope.conceptos, function(costo, key){
            $scope.subtotal += (costo.valor * costo.cantidad);
            if ($scope.tipoFactura == 2){
                $scope.iva += (costo.valor * costo.cantidad) * (costo.porcentaje / 100 );
            }
            $scope.valorTotal += ($scope.iva) + (costo.valor * costo.cantidad);
        });
    };

    $scope.costos= $costosArray;

    $scope.actualizarSaldo = function(concepto) {

        concepto.valor = $scope.costos[concepto.id].valor;
        concepto.porcentaje = $scope.costos[concepto.id].porcentaje;
    };

    $scope.buscar = function(documento) {
        $scope.addCliente = false;

        $scope.spinner = true;

        $http({
            method: 'GET',
            url: url,
            params: {
                documento: $scope.documento
            }
        }).then(function successCallback(response) {
            $scope.spinner = false;
            $scope.cliente =  response.data;
        }, function errorCallback(response) {
            console.log('Se produjo un error en la solicitud. Status Code: ' + response.status + ' Status statusText: ' + response.statusText);
        });

    };

    $scope.message = false;

    $scope.submitClienteForm = function(Form) {
        urlPost = "{!! route('pg_facturacion::factura.addDocumento') !!}";
        // check to make sure the form is completely valid
        if (Form.$valid) {

            $http({
                method  : 'POST',
                url     : urlPost,
                params: {
                    data    : $scope.user
                },
                // pass in data as strings
                //                    headers : { 'Content-Type': 'application/x-www-form-urlencoded' }  // set the headers so angular passing info as form data (not request payload)
            })
                .success(function(data) {
                    if (data.documento) {
                        // if successful, bind success message to message
                        $scope.message = true;
                        $scope.buscar(data.documento);
                        $scope.documento = data.documento;
                        $scope.user = {};
                        Form.$setPristine(true);
                        Form.$setUntouched();

                    } else {
                        // if not successful, bind errors to error variables

                    }
                });
        }

    };
    $scope.$watch($scope.total);
});


app.controller('CajaController', function($scope) {
    $scope.fechareporte = new Date();
    $scope.fecha = new Date();
    $scope.fechaInicio = new Date($vigencia, $mes_recaudos -1, 01);
    $scope.fechaFin = new Date($vigencia, $mes_recaudos -1 , 31);
});

app.controller('HistorialCajaController', function($scope) {
    $scope.fechareporte = new Date();
});

app.controller('CodigoController', function($scope) {
    $scope.automatico = true;
});

app.controller('ImportadorController', function($scope) {
    $scope.fecha = new Date();
});
